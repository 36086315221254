.localized-en {
	.selectbox {
		&--trip-type {
			& > select {
				padding-left: 7.5rem;
			}		
		}
		&--ticket-type-inline {
			& > select {
				padding-left: 8.9rem;
			}
		}
	}
	.filters .filters-top .title {
		font-size: 1.6rem;
	}
	@media only screen and (max-device-width: 580px) {
		header {
			.logo {
				margin-left: 5.8rem;

				.img-logo {
					height: 3.5rem;
				}
			}
		}

		@include breakpoint_up(xs) {
			header {
				.logo {
					.img-logo {
						height: 4rem;
					}
				}
			}
		}
	}
}