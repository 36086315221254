/* URI references */
$VERSION: "v#{random(99999)+100000}";
$STATIC: "/static/#{$VERSION}";
$GLOBAL: "#{$STATIC}/images/global";
$SHOWRESULTS: "#{$STATIC}/images/showresults";
$MOBILE: "#{$STATIC}/images/mobile";
$icomoon-font-path: "#{$STATIC}/fonts";


/* Breakpoints */
$xxxs: 320px; /* small phones (iPhone 5) */
$xxs: 375px; /* medium phones (iPhone 6/7/8) */
$xs: 414px; /* large phones (iPhone 6/7/8 +) */
$sm: 576px; /* extra large phones, landscape phones, small tablets */
$md: 768px; /* medium tablets */
$lg: 992px; /* large tablets, laptops */
$xl: 1220px; /* large laptops, desktops */

$ads: 1150px; /* wide enough to display lateral ads */

$breakpoints: (
		"xxxs": $xxxs,
		"xxs": $xxs,
		"xs": $xs,
		"sm": $sm,
		"md": $md,
		"lg": $lg,
		"xl": $xl,
		"ads": $ads
);


/* Gridlex settings*/
$gl-colCount: 12 !default;
$gl-gridName: grid !default;
$gl-colName: col !default;
$gl-attributeName: class !default;
$gl-gutter: 2% !default; // Total left + right
$gl-gutter-vertical: 2% !default;
$gl-mq-width: 'max-width' !default;
$gl-mq-list: (
		lg: $xl,
		md: $lg,
		sm: $md,
		xs: $sm
) !default;

/* border radius */
$borderRadius-xs: .4rem;
$borderRadius-sm: .9rem;
$borderRadius-md: 3rem;
$borderRadius-lg: 6rem;

/* colors */
$black: #333;
$white: #fff;

// blue
$color-blue-900: #31527A;
$color-blue-800: #2E5B8B;
$color-blue-700: #53658C;
$color-blue-600: #60779E;
$color-blue-500: #429CFF;
$color-blue-400: #7A8FB2;
$color-blue-300: #AFC6E8;
$color-blue-200: #D6E0F1;
$color-blue-100: #E8F3FF;
$color-blue-000: #F1F4FA;

// grey
$color-grey-900: #333333;
$color-grey-800: #424242;
$color-grey-700: #6E6E6E;
$color-grey-600: #8D949E;
$color-grey-500: #969696;
$color-grey-400: #C4C3C3;
$color-grey-300: #BEC2C7;
$color-grey-200: #E6E8ED;
$color-grey-100: #F7F7F7;

// gold
$color-gold-500: #FBCC2C;
$color-gold-300: #FCDB6B;
$color-gold-100: #FFF7E0;

// green
$color-green-700: #3D9C88;
$color-green-500: #34AD2D;
$color-green-300: #9AD897;
$color-green-100: #E7F7F4;

// red
$color-red-700: #A53125;
$color-red-500: #E83151;
$color-red-300: #FF5756;
$color-red-100: #EBB4B0;

// orange
$color-orange-300: #F5A623;
$color-orange-100: #FACE86;

// purple
$color-purple-300: #AF77BF;
$color-purple-100: #FBF2FD;

/* themed colors classified into functions - font color, bg color, border color */

/******* font color *******/
// core font colors
$brand-font-color: $color-blue-900;
$main-font-color: $color-blue-800;
$cta-font-color: $color-gold-500;

// neutral font colors
$neutral-main-font-color: $color-grey-900;
$neutral-secondary-font-color: $color-grey-700;
$disabled-font-color: $color-grey-500;
$mobile-unselected-tab-font-color: $color-grey-400;
$very-disabled-font-color: $color-grey-200;
$link-font-color: $color-blue-500;
$error-font-color: $color-red-500;
$warning-font-color: $color-orange-300;
$correct-font-color: $color-green-500;

// results font colors
$result-iata-font-color: $color-grey-800;
$result-details-font-color: $color-blue-400;
$result-price-font-color: $color-red-700;

// banner font colors
$banner-desc-font-color: $color-grey-600;
$banner-header-font-color: $color-grey-300;

// last minute font colors
$lm-price-font-color: $color-red-500;

// booking font colors
$booking-description-font-color: $color-blue-600;
$booking-subInfo-font-color: $color-grey-600;

/******* bg color *******/
// basic bg colors
$autocomplete-header-bg: $color-blue-700;
$neutral-secondary-bg: $neutral-secondary-font-color;
$disabled-bg: $color-grey-500;
$mobile-unselected-tab-bg: $mobile-unselected-tab-font-color;
$very-disabled-bg: $very-disabled-font-color;
$input-bg: $color-blue-000;
$input-secondary-bg: $color-grey-100;
$main-page-bg: $color-grey-100;
$container-bg: $white;
$highlighted-text-bg: $color-gold-300;
$hover-bg: $color-gold-100;
$red-days-bg: $color-red-500;
$recommend-bg: $color-green-500;
$flex-bg: $color-blue-500;
$flex-bg--light: $color-blue-100;
$cc-bg: $color-green-700;
$cc-bg--light: $color-green-100;
$baggage-bg: $color-purple-300;
$baggage-bg--light: $color-purple-100;
$cabinBaggage-bg: $color-red-300;

// results bg colors
$cc-user-bg: $color-green-700;
$cc-airline-bg: $color-green-300;
$result-lay-over-bg: $color-green-300;
$result-short-lay-over-bg: $color-orange-100;
$result-long-lay-over-bg: $color-red-100;
$result-details-bg: $color-blue-000;

// button bg colors
$brand-btn-bg: $brand-font-color;
$main-btn-bg: $main-font-color;
$result-tab-btn-bg: $color-blue-200;
$secondary-btn-bg: $color-blue-200;
$cta-btn-bg: $cta-font-color;
$clickout-btn-bg: $color-gold-300;

/******* border color *******/
$brand-border-color: $brand-font-color;
$main-border-color: $main-font-color;
$cookie-border-color: $color-blue-300;
$disabled-border-color: $disabled-bg;
$mobile-unselected-tab-border-color: $mobile-unselected-tab-font-color;
$neutral-border-color: $very-disabled-font-color;
$main-page-border-color: $main-page-bg;
$cc-info-popup-border-color: $color-green-700;
$cta-border-color: $cta-font-color;
$clickout-bg-border-color: $clickout-btn-bg;
$warning-border-color: $color-orange-300;

/* box shadows */
$lightBoxShadow: 1rem .9rem 1.6rem 0 rgba(49,82,122,0.28);
$thinBoxShadow: 0 0.2rem 0.2em 0 rgb(49 82 122 / 10%);
$thinInverseBoxShadow: 0 -0.2rem 0.2em 0 rgb(49 82 122 / 10%);
$glowBoxShadow: 0 0 1.5rem 1.5rem $color-gold-500;

/* data-URI images */
$resultStopTimeImg: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI0cHgiIGhlaWdodD0iNXB4IiB2aWV3Qm94PSIwIDAgNCA1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICAgICAgPHRpdGxlPkdyb3VwIDM1PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPiAgICAgICAgPHJlY3QgaWQ9InBhdGgtMSIgeD0iMCIgeT0iMCIgd2lkdGg9IjUiIGhlaWdodD0iNSI+PC9yZWN0PiAgICA8L2RlZnM+ICAgIDxnIGlkPSJBbGwiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IkFydGJvYXJkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjM5LjAwMDAwMCwgLTUyNS4wMDAwMDApIj4gICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMzUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYzOC4wMDAwMDAsIDUyNS4wMDAwMDApIj4gICAgICAgICAgICAgICAgPG1hc2sgaWQ9Im1hc2stMiIgZmlsbD0id2hpdGUiPiAgICAgICAgICAgICAgICAgICAgPHVzZSB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4gICAgICAgICAgICAgICAgPC9tYXNrPiAgICAgICAgICAgICAgICA8ZyBpZD0iUmVjdGFuZ2xlLTQiPjwvZz4gICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoLTMzIiBzdHJva2U9IiNGNUE2MjMiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIG1hc2s9InVybCgjbWFzay0yKSIgcG9pbnRzPSI2LjA1OTk5NzU2IDAuMDMwMDI5Mjk2OSAxLjk3OTk4MDQ3IDIuNTcwMDA3MzIgNi4wNTk5OTc1NiA0LjgyMDAwNzMyIj48L3BvbHlsaW5lPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==";
$resultStopTimeAfterImg: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI1cHgiIGhlaWdodD0iNXB4IiB2aWV3Qm94PSIwIDAgNSA1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICAgICAgPHRpdGxlPkdyb3VwIDM2PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIwLjc5OTk4Nzc5MyA2LjEzMDAwNDg4IDUuOTk5OTg3NiA2LjEzMDAwNDg4IDUuOTk5OTg3NiAxMC44Njk5OTUxIDAuNzk5OTg3NzkzIDEwLjgwMDAwMDIiPjwvcG9seWdvbj4gICAgPC9kZWZzPiAgICA8ZyBpZD0iQWxsIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJBcnRib2FyZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY2Mi4wMDAwMDAsIC01MDAuMDAwMDAwKSI+ICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTM2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2NjEuMDAwMDAwLCA0OTQuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC0zNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS4wMDAwMDAsIDAuMDAwMDAwKSI+PC9nPiAgICAgICAgICAgICAgICA8bWFzayBpZD0ibWFzay0yIiBmaWxsPSJ3aGl0ZSI+ICAgICAgICAgICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPiAgICAgICAgICAgICAgICA8L21hc2s+ICAgICAgICAgICAgICAgIDxnIGlkPSJSZWN0YW5nbGUtNCI+PC9nPiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMS45ODYwMjcyNiw4LjYxMTA4OTc3IEw1LjUsMTAuNTUwNjMgTDUuNSwxNSBDNS41LDE1LjgyODQyNzEgNi4xNzE1NzI4OCwxNi41IDcsMTYuNSBMNjEsMTYuNSBDNjEuODI4NDI3MSwxNi41IDYyLjUsMTUuODI4NDI3MSA2Mi41LDE1IEw2Mi41LDIgQzYyLjUsMS4xNzE1NzI4OCA2MS44Mjg0MjcxLDAuNSA2MSwwLjUgTDcsMC41IEM2LjE3MTU3Mjg4LDAuNSA1LjUsMS4xNzE1NzI4OCA1LjUsMiBMNS41LDYuNDEzNzYyNTEgTDEuOTg2MDI3MjYsOC42MTEwODk3NyBaIiBpZD0iUmVjdGFuZ2xlLTExIiBzdHJva2U9IiNDODJGMjUiIG1hc2s9InVybCgjbWFzay0yKSI+PC9wYXRoPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==";
$showResultSearchBoxImg: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzc1IiBoZWlnaHQ9IjMzOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMCAwaDM3NXYzMTdjLTg4IDE0LjMzNC0xNTAuNTkzIDIxLjUtMTg3Ljc4IDIxLjUtMzcuMTg2IDAtOTkuNTkzLTcuMTY2LTE4Ny4yMi0yMS41VjB6IiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg=="; // newIndexPage-bg
$searchBoxBottomImg: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzNzUgMzYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgo8dGl0bGU+R3JvdXAgMjwvdGl0bGU+CjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgo8ZGVmcz4KPHBhdGggaWQ9ImEiIGQ9Im0wIDNoMzc1djIuNjcyOGMtODcuNTY4IDE2LjIxOC0xNDkuOTkgMjQuMzI3LTE4Ny4yOCAyNC4zMjdzLTk5Ljg1OS04LjEwOTEtMTg3LjcyLTI0LjMyN3YtMi42NzI4eiIvPgo8ZmlsdGVyIGlkPSJiIiB4PSItMS45JSIgeT0iLTE4LjUlIiB3aWR0aD0iMTAzLjclIiBoZWlnaHQ9IjE1MS45JSI+CjxmZU9mZnNldCBkeD0iMCIgZHk9IjIiIGluPSJTb3VyY2VBbHBoYSIgcmVzdWx0PSJzaGFkb3dPZmZzZXRPdXRlcjEiLz4KPGZlR2F1c3NpYW5CbHVyIGluPSJzaGFkb3dPZmZzZXRPdXRlcjEiIHJlc3VsdD0ic2hhZG93Qmx1ck91dGVyMSIgc3RkRGV2aWF0aW9uPSIyIi8+CjxmZUNvbG9yTWF0cml4IGluPSJzaGFkb3dCbHVyT3V0ZXIxIiB2YWx1ZXM9IjAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgIDAgMCAwIDAgMCAgMCAwIDAgMC4xOTMyMTc4NDQgMCIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgo8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC00NTcpIj4KPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCA0NTcpIj4KPHVzZSBmaWxsPSJibGFjayIgZmlsdGVyPSJ1cmwoI2IpIiB4bGluazpocmVmPSIjYSIvPgo8dXNlIGZpbGw9IiNGRkZGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgeGxpbms6aHJlZj0iI2EiLz4KPHJlY3QgeD0iLjUiIHk9Ii41IiB3aWR0aD0iMzc0IiBoZWlnaHQ9IjUiIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSIjZmZmIi8+CjwvZz4KPC9nPgo8L2c+Cjwvc3ZnPgo="
