
@mixin breakpoint_up($break) {
	@media (min-width: map-get($breakpoints, $break)) {
		@content;
	}
}

@mixin breakpoint_down($break) {
	$bp: map-get($breakpoints, $break);
	$nw: $bp - 0.2px;

	@media (max-width: $nw) {
		@content;
	}
}

@mixin breakpoint_between($min, $max) {
	$minbp: map-get($breakpoints, $min);
	$maxbp: map-get($breakpoints, $max) - 1px;

	@media (min-width: $minbp) and (max-width: $maxbp) {
		@content;
	}
}

@mixin smoothScroll() {
	-webkit-overflow-scrolling: touch;
}

@mixin resetBtn() {
	border: none;
	margin: 0;
	overflow: visible;
	background: transparent;
	color: inherit;
	font: inherit;
	line-height: normal;
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;
	-webkit-appearance: none;
}

@mixin disableTapHighlight() {
	-webkit-tap-highlight-color: transparent;
}

@mixin blueGradient() {
	background-image: linear-gradient(-129deg, #2E84E1 0%, #3177C2 0%, $main-btn-bg 68%);
}

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@-ms-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	}
}

@mixin background-image($path, $size:false) {
	background-image: url("#{$path}.svg");
	@if($size) {
		background-size: $size;
	}
	.no-svg & {
		background-image: url("#{$path}.png");
	}
}

@mixin font-smoothing() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin fadeIn($arg) {
	$keyframe-name: fadeIn;
	$duration: $arg;
	animation: $keyframe-name $duration;
	opacity: 1;
}

@mixin fadeOut($arg) {
	$keyframe-name: fadeOut;
	$duration: $arg;
	animation: $keyframe-name $duration;
	opacity: 0;
}

@mixin keyframe-fadeIn {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}

@mixin keyframe-fadeOut {
	0%   { opacity: 1; }
	100% { opacity: 0; }
}

@mixin clearfix {
	zoom: 1;
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin arrowTopMiddle($color) {
	position: absolute;
	top: 100%;
	left: calc(50% - 1rem);
	margin-top: .5rem;
	width: 2rem;
	height: 2rem;
	z-index: -1;
	background-color: $color;
	clip-path: polygon(50% 0, 95% 60%, 0 60%);
}

@mixin arrowRightFullHeight($height, $color) {
	position: absolute;
	top: 0;
	border-top: ($height*0.5) solid transparent;
	border-bottom: ($height*0.5) solid transparent;
	border-left: 1rem solid $color;
	width: 0;
	height: 0;
}

@mixin topBlueBorder {
	position: absolute;
	content: '';
	width: 100%;
	height: 0.5rem;
	background-color: #344f76;
	top: -0.3rem;
	left: 0;
	border-radius: $borderRadius-xs $borderRadius-xs 0 0;
	opacity: 0.88;
	background-image: linear-gradient(-228deg, rgba(53, 79, 119, 0.83) 0%, $brand-btn-bg 77%, $brand-btn-bg 77%);
}

@mixin bgShadowMenuSB {
	position: absolute;
	left: -0.4rem;
	content: '';
	height: calc(100% + 8.1rem);
	top: -7.7rem;
	border-radius: $borderRadius-xs;
	width: calc(100% + 0.8rem);
	box-shadow: 0.1rem 0.6rem 1rem 0 rgba(46,91,139,0.30);
}

@mixin bgZoom {
	border: 0.4rem solid $input-bg;
	top: -0.4rem;
	left: -0.4rem;
}

// utility / helper classes

@each $name, $size in $breakpoints {
	.hidden-#{$name}-up {
		@media (min-width: #{$size}) {
			display: none !important;
		}
	}
}

@each $name, $size in $breakpoints {
	.hidden-#{$name}-down {
		@media (max-width: $size - 0.2px) {
			display: none !important;
		}
	}
}

@mixin responsive($class) {
	@each $size, $breakpoint in $breakpoints {
		@if $size != 'xs' { // 'xs' applies by default, so no need for a media query
			@media (min-width: $breakpoint) {
				// Add !important to override any existing styles
				body .#{$size}\:#{$class} { @content; }
			}
		}
		@else {
			.#{$class} { @content; }
		}
	}
}

@mixin imgCopyrightText {
	position: absolute;
	content: '© TT Nyhetsbyrån AB';
	display: block;
	color: $booking-subInfo-font-color;
	font-size: 1rem;
	padding: .3rem .6rem;
	//background-color: rgba(0,0,0,0.3);
}
