.localized-ar {

	.selectbox {
		& > select {
			padding-left: 2.4rem;
			padding-right: 0.6rem;
		}
		&--trip-type {
			& > select {
				padding-right: 9.2rem;
			}
		}
		&--ticket-type-inline {
			& > select {
				padding-right: 8.4rem;
			}
		}
	}

	.checkbox {
		$this: &;

		&__tick {
			&:before {
				margin-left: 0.6rem;
			}

			&:after {
				margin-right: 0.6rem;
			}
		}

		&__icon {
			&:before {
				margin-right: 0.1rem;
				margin-left: 0.6rem;
			}

			&:after {
				margin-right: 0.6rem;
			}
		}
	}

	.latest-searches {
		.search {
			& > div:last-child {
				text-align: left;
			}
		}
	}

	section.container {
		&.container--full-width {
			display: none;
		}
	}

	.pull-left {
		float: right;
	}

	.pull-right {
		float: left;
	}

	.price {
		direction: ltr;
	}

	.main-inner {
		direction: rtl;
	}

	.global-container {
		direction: rtl;
		position: relative;

		.bound {
			.top-time {
				float: left !important;
			}
		}
	}
	//Resenärer, första sidan
	.search-box {
		.input-container {
			.input {
				padding: 0;
			}
		}

		.search-box-calendar-top .section {
			font-size: 1.7rem;
		}

		.search-box-adults {
			padding-right: 0;
			@include breakpoint_up(md) {
				padding-right: 0;
			}
		}

		.search-box-children {
			padding-left: 0;
			padding-right: 0.5rem;
			@include breakpoint_up(md) {
				padding-right: 1rem;
			}
		}

		.child-ages-wrapper {
			float: right;
			margin-right: -1rem;
		}

		.search-box-child-selects .label {
			margin-right: 1rem;
			font-size: 1.5rem;
		}

		.search-box-view {
			&.showresult-view {
				.cell-split {
					& > div {
						padding-right: 0;
						padding-left: 0.5rem;
						&:nth-child(2n) {
							padding-right: 0.5rem;
							padding-left: 0;
						}
					}
				}
			}
			&.openjaw-view {
				.search-box-extra-options-container .selectbox select {
					margin-right: -0.4rem;
				}

				.to-container {
					.icon {
						transform: scale (-1, 1);
					}
				}

				.search-button-container {
					.btn-icon {
						border-right: 0.1rem solid rgba($black, .1);
					}
				}

				.input-container {
					.input {
						padding: 0;
					}
				}

				.add-leg-section {
					.add-route {
						float: left;
						padding-right: 2rem;
					}
				}
			}
		}


		.btn > .btn-icon:nth-child(2) {
			border-right: 0.1rem solid rgba($black, .1);
		}

		.search-box-passenger-select {
			padding-left: 1.4rem;

			&:first-child {
				padding-right: 0;
			}

			&:last-child {
				padding-left: 0;
			}
		}

		.search-box-passenger-selects {
			margin-top: 0;
			width: 100%;
			font-size: 1.5rem;
		}
	}

	.checkbox label span {
		font-size: 1.5rem;
	}

	.search-box.is-showresult.show-openjaw {
		.search-box-view.openjaw-view .search-box-extra-options-container {
			//float: right;
			margin-right: 0.5rem;

			.showresult-add-route-wrapper {
				left: 0;
				right: auto;
			}
		}
	}

	.selectbox {
		.select {
			//margin-right: -1rem;
			direction: rtl;
			font-size: 1.8rem;
		}

		&.ticket-type-container,
		&.trip-type-container {
			display: table;
			width: 100%;
			margin-bottom: 1rem;
		}

		.ticket-type-label,
		.trip-type-label {
			display: table-cell;
			font-size: 1.8rem;
			//margin-left: 1rem;
		}

		/*
		.select-ticket-type,
		.select-trip-type {
			display: table-cell;
			padding-left: 0;
			width: 100%;
			padding-right: 0;
		}
		*/

		&:after {
			left: 0.6rem;
			right: auto;
		}
	}

	.show-menu {
		direction: rtl;

		header {
			.inner {
				position: relative;
				margin-bottom: 0;
				z-index: 10;
				min-height: 6rem;
				background-color: $main-btn-bg;
			}
		}

		.hamburger-menu {
			z-index: 10;
		}
	}

	.fotbollsem {
		.emblem {
			right: auto;
			left: -1.2rem;
		}
		@include breakpoint_up(lg) {
			.emblem {
				.emblem-text {
					text-align: right;
					display: block;
				}
			}
		}
	}

	.top-information {
		h1,
		h2 {
			direction: rtl;
		}

		.section {
			@include breakpoint_up(lg) {
				padding: 1rem;

				&.blue {
					h2.title {
						text-align: right;
					}
				}
			}
		}
		@include breakpoint_up(md) {
			&.blue {
				h2.title {
					text-align: right;
				}
			}
		}
	}

	.box h2.box-title {
		font-size: 2.0rem;
	}

	.box .box-description {
		font-size: 1.7rem;
	}

	.abTooltip {
		&--visible {
			&:before {
				right: 19.7rem;
			}
		}

		&--mediumVisible {
			right: -26.1rem;
			left: 3.4rem;
			text-align: right;

			&:before {
				right: 14.2rem;
			}
		}
	}

	footer {
		direction: rtl;

		.link {
			direction: rtl;
		}

		.container--local {
			direction: rtl;
			/*.local-title {
				font-size: 1.7rem;
				margin-right: 7.5rem;
			}*/
		}

		.footer-reklam__text {
			margin-right: 1rem;
		}

		.copyright {
			margin-bottom: 1rem;
		}
		@include breakpoint_up(lg) {
			padding-top: 2rem;

			.footer-reklam__link {
				margin-right: 1rem;
			}
		}
		@include breakpoint_up(xl) {
			.footer-reklam__link {
				margin-right: 1rem;
			}

			.container--local {
				.local-link {
					display: block;
					float: right;
				}
			}
		}
	}

	.content {
		position: relative;
		margin: 0 auto;
		padding: 0;
		overflow: visible;
		clear: both;
	}

	.icon.icon {
		transform: scaleX (-1);
	}

	&.home {
		.top-information {
			direction: rtl;
		}

		.latest-searches {
			.section.right {
				text-align: left;
			}

			.list {
				margin: -5rem 0 0;
			}
		}

		.search-box {
			position: relative;

			.search-button-container {
				.btn-search {
					display: table;
					float: left;
				}
			}

			.search-box-choice-radios {
				.radio:first-child {
					padding-left: 2rem;
				}
				.label {
					margin-right: 0.5rem;
					> span {
						padding-right: 0.4rem;
					}
				}
				.input[type=radio]:checked + label::before {
					background-position: -1.5rem 0;
				}
			}

			.remove-route {
				position: relative;
				//display: block;
				width: 3.4rem;
				height: 3.4rem;
				background-color: $white;
				text-decoration: none;
				color: $main-font-color;
			}

			.leg {
				right: 0;
				left: auto;
			}
		}
	}

	.sectionIntro {
		clear: left;
	}

	.lastminute {
		.container h2 {
			font-size: 1.8em;
		}

		.city-image {
			.pricetag {
				direction: ltr;
				float: left;
			}
		}
	}
	@include breakpoint_up(xl) {
		.latest-searches {
			right: auto;
			left: 0;
			float: none;
		}
	}

	.home-intro__sectionHeader {
		direction: rtl;
	}
	@include breakpoint_up(lg) {
		.home-intro__sectionHeader {
			text-align: right;
		}
	}
	//Första sidan - billigast
	.box--cheapest-destinations {
		.box-top {
			direction: ltr;
		}

		.list-item:first-child {
			direction: ltr;
		}

		.list {
			.list-link.price {
				text-align: left;
			}
		}
		@include breakpoint_up(lg) {
			.categories {
				float: right;
				width: 33.33%;
			}
		}
	}
	//Första sidan - Mest sökta
	section.container h1 {
		padding-left: 1rem;
		margin-top: 0.5rem;
		font-size: 1.8rem;
	}
	@include breakpoint_up(lg) {
		.most-searched {
			display: flex;
		}
	}
	@include breakpoint_up(xl) {
		.box-hitlists {
			float: right;
			margin-right: 0.2002%;
			margin-bottom: 0;
			margin-top: 0;
		}

		.latest-searches {
			right: auto;
			left: 0;
			float: right;
			margin-right: 0;
		}
	}

	&.lastminute {
		.hero-information {
			margin-bottom: -1rem;
		}

		.sort-view {
			.section {
				text-align: right;
			}
		}

		.city-image {
			.pricetag {
				direction: ltr;
				float: left;
			}
		}

		.breadcrumbs {
			.pages {
				float: left;
			}

			.page {
				float: right;
				padding: 0 3rem 0 0;
			}

			.page.current {
				padding-left: 0;
				margin-left: 0;
			}

			.pages:before {
				float: right;
			}
		}

		.footer-information p {
			font-size: 1.6rem;
			text-align: right;
			margin-right: 1rem;
		}

		.menu {
			.menu-text {
				direction: ltr;
				text-align: center;
				font-size: 2.0rem;
			}

			.sorting-main {
				display: table;
				left: 0;
				right: auto;
				border-right: 0;
			}

			.menu-list {
				display: table;
			}
		}

		.menu-secondary {
			.sorting-columns {
				display: none;
				right: 0;
				left: auto;
			}

			.menu-mobile {
				display: block;
				float: right;
				margin-right: 1rem;
			}
		}

		.container.list-view {
			.price-disclaimer {
				padding: 1em 0 .5em 1em;
				text-align: left;
				font-size: 1.3em;

			}
		}

		.lastminute-list-container .lastminute-list-row {
			.lastminute-list-cell {
				.city,
				.iata,
				.title,
				.trip-date,
				.trip-info,
				.trip-length {
					text-align: right;
				}

				.price-found {
					text-align: left;
					left: 2rem;
					right: auto;
				}

				.outclick-box {
					float: left;

					.discount-badge {
						right: 2.4rem;
						left: auto;
						direction: ltr;

						.badge-edge {
							transform: scalex(-1);
							left: 0;
							right: auto;
						}
					}

					.price-original {
						left: 14.5rem;
						right: auto;
						direction: ltr;
					}

					.price-original-mobile {
						direction: ltr;
						left: 0;
						right: auto;
					}

					.price-mobile {
						direction: ltr;
					}

					.price-original-mobile:after {
						display: inline-block;
					}
				}
			}

			.lastminute-list-cell.tripcontainer-cell {
				&.tr:first-child {
					right: -2rem;
				}

				&.td:nth-child(2) {
					position: relative;
					float: right;
				}

				tr:first-child td:nth-child(2):before {
					right: -2rem;
					left: auto;
					transform: scalex(-1);
				}
			}
		}
	}

	&.information {
		.top-information {
			background-color: transparent;
			border-bottom: 0;
			margin-top: 1rem;
		}

		.information-slides {
			margin-right: 0;
			margin-left: 2rem;
		}

		main {
			.container {
				min-width: 30rem;
				.accordion {
					margin-bottom: 2rem;
					padding: 0 1rem;
				}

				.accordion-slide {
					font-size: 1.2em;
					margin-bottom: -0.1rem;

					.accordion-slide-content {
						color: $neutral-secondary-font-color;
						font-size: 0.8em;
						line-height: 1.6;
					}
				}

				.accordion-slide-top {
					line-height: 2;

					.icon {
						position: relative;
						top: 0.1rem;
						float: right;
						margin-left: 1.5rem;
						margin-right: 0;
					}
				}

				.accordion-slide-top:before {
					left: 1rem;
					right: auto;
				}
			}
		}

		.col-left {
			width: 100%;
			max-width: 945px;
		}

		.col-right {
			position: relative;
			padding-right: 1rem;
		}

		.tab-btns {
			position: relative;
			margin-top: 0;

			.tab-btn.active {
				[class*=" icon-"],
				[class^="icon-"] {
					content: "\e61f";
					float: right;
					margin-top: 0.6rem;
				}
			}

			.tab-btn.active:before {
				display: block;
				content: " ";
				position: absolute;
				right: 100%;
				top: 50%;
				border: solid transparent;
				width: 0;
				height: 0;
				pointer-events: none;
			}

			.tab-btn.active:after {
				display: block;
				border-right-color: $main-border-color;
				border-width: 1.6rem;
				margin-top: -1.6rem;
				transform: rotateY(180deg);
				right: -3.1rem;
				left: auto;
			}

			.tab-btn.active {
				background-color: $main-btn-bg;
				color: $white;

				.tab-btn.active:before {
					display: block;
				}
			}

			.tab-btn {
				[class*=" icon-"],
				[class^="icon-"] {
					speak: none;
					float: right;
					margin-top: 0.6rem;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
				}
			}
		}

		.slide ul {
			padding-right: 2rem;
		}

		.qna-links ul {
			padding-right: 1.8rem;
		}

		.contact-form {
			.form-submit {
				float: left;
				width: 10rem;
				margin-right: 3rem;
			}
		}

		@include breakpoint_up(lg) {
			.view-mobile {
				display: none;
			}
		}
	}

	&.prisjagaren {
		.timeline-data-canvas-prices-price {
			direction: ltr;
		}

		.timeline-data-inner-container {
			border-right: 0.1rem solid $neutral-border-color;
		}

		.filters-top .box .box-title {
			direction: ltr;
		}

		.input-container .input {
			margin-right: 0;
		}

		.input-container.large {
			margin-left: 0;
		}

		.prisjagaren.show-calendar {
			.calendar {
				display: table;
			}
		}

		.menu {
			padding: 0;
			.menu-text {
				text-align: center;
				font-size: 1.6rem;
			}

			.months .menu-list-item {
				padding-left: 1.5rem;
			}

			.menu-mobile {
				margin: 0;
			}

			.filters-top {
				.section {
					padding: 1rem;
				}
			}

			.menu-desktop {
				display: block;
				height: 6rem;
				position: relative;
			}

			.view-type {
				display: table-cell;
			}
		}

		.menu-secondary {
			.menu-mobile {
				display: none;
			}
		}

		.timeline-data-canvas {
			padding-right: 8rem;
			padding-left: 0;
		}

		.timeline-data-canvas-days-day {
			float: right;
		}

		.timeline-data-canvas-prices {
			right: 0;
			left: auto;
		}
	}

	&.prisbevakaren {
		.top-information section.container h1 {
			font-size: 2.0rem;
		}

		.free-datepicker-container {
			right: 0 !important;
			left: auto !important;
		}

		.search-box {
			max-width: 1200px;
			margin-left: 2.5rem;
			margin-right: -1rem;

			.btns {
				.btn {
					float: right;
					margin-left: 0.5rem;
				}

				.btn-clear {
					margin-right: 0;
					margin-top: 0;
				}
			}

			.input-container {
				.icon {
					right: auto;
					left: 0;
				}
			}

			.slider-value {
				right: 1.4rem;
			}
		}

		.box .box-title {
			font-size: 2.0rem;
		}

		.box-info ul {
			padding: 0 2.5rem;
			font-size: 1.6rem;
		}

		.ui-datepicker {
			.ui-datepicker-next {
				left: 0;
				right: auto;
				transform: scaleX(-1);
			}

			.ui-datepicker-prev {
				right: 0;
				left: auto;
				transform: scaleX(-1);
			}
		}
	}

	.widget {
		.main-wrapper {
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			height: 25rem;
			width: 25rem;

			.top {
				width: 25rem;
				height: 4rem;
				background-color: $brand-btn-bg;
				text-align: center;
				position: relative;
				z-index: 2;

				img {
					padding-top: 0.5rem;
					width: 60%;
				}
			}

			.image {
				position: relative;
				z-index: 1;
				left: 0;

				img {
					width: 25rem;
				}
			}

			.text {
				width: 25rem;
				font-family: "Avenir Next";
				font-size: 1.5rem;
				font-weight: 100;
				color: $white;
				padding: 0.5rem;
				position: absolute;
				z-index: 2;
				bottom: 0;
				height: 6rem;
				left: 0;
				background-color: $brand-btn-bg;

				.city {
					font-weight: 700;
					font-size: 1.7rem;
				}

				.price {
					direction: ltr;
					font-weight: 700;
					font-size: 1.7rem;
					color: $cta-font-color;
				}
			}
		}
	}

	&.information,
	&.last-minute,
	&.last-minute,
	&.prisbevakaren,
	&.prisjagaren {
		.menu-desktop {
			display: none;
		}

		.menu-mobile {
			display: block;
		}
	}

	.top-information {
		direction: rtl;

		.container {
			.section.left {
				float: right;
				padding: 1rem 0;
				margin-right: 1rem;
			}
			.section.right {
				.partner-logo {
					right: auto;
					left: 1.1rem;

					img {
						margin: 0 0.4rem 0 0;
					}
				}
			}

			h2 {
				text-align: right;
				font-size: 1.2rem;
				display: block;
			}
		}
	}
	@include breakpoint_up(md) {
		.top-information {
			.container {
				h2 {
					text-align: right;
					font-size: 1.7rem !important;
				}
			}
		}
	}
	@include breakpoint_up(md) {
		.top-information {
			.container {
				padding: 0 1rem;

				.section.left {
					padding: 0.4rem;
				}
			}
		}

		header {
			//padding-bottom: 1rem;
		}

		.search-box-from-to-fields {
			.from-container,
			.to-container {
				label {
					display: block;
				}
			}
		}
	}

	.search-box-view.default-view {
		.checkbox.reverse {
			text-align: left;
		}

		.search-box-calendar.to {
			float: right;
		}

		.search-box-calendar.from {
			float: right;
			margin-right: 0.2002%;
		}

		.ui-datepicker-prev {
			right: 0;
			left: auto;
		}

		.ui-datepicker-next {
			left: 0;
			right: auto;
		}

		.ui-datepicker-prev {
			&:before {
				content: "\e60a";
				left: 0;
			}
		}

		.ui-datepicker-next {
			&:before {
				right: 0;
				content: "\e609";
			}
		}
	}

	&.search-box-other {
		padding: 0 1rem;
	}

	.ui-datepicker {
		.ui-datepicker-next {
			left: 0;
			right: auto;
		}

		.ui-datepicker-prev {
			right: 0;
			left: auto;
		}
	}
	//Destinationer + Flygboag + Information (gul etikett)
	.box-icon {
		&.top-icons-info-about {
			left: 1rem;
			right: auto;
		}

		&.top-icons-smiley {
			left: 1rem;
			right: auto;
		}
	}
	//Prisjägaren
	//(icon aircraft i till/från ruta)
	.input-container {
		.label {
			right: 0.4rem;
			left: auto;
		}
	}

	.icon {
		&.icon,
		&.input-container,
		&.large {
			right: auto;
			left: -0.2rem;
		}

		.input-container.large {
			.icon {
				right: auto;
				left: 0.5rem;
				transform: scale (-1, 1);
			}
		}
	}
	//Månader/Kalender/karta
	.menu {
		.menu-list {
			float: left;
		}

		.menu-section {
			&:nth-child(2) {
				left: 0;

				.menu-list-item:last-child {
					&:after {
						content: none;
					}
				}
			}
		}
	}
	//Sista Minuten
	//dropdowns
	.section {
		.multi-select__btn-text {
			font-size: 1.6rem;
			text-align: right;
			padding-right: 0.4rem;
			padding-left: 2rem;

			.icon.icon {
				left: -0.2rem;
				top: -0.3rem;
				transform: scaleX (-1);
			}
		}
	}

	&.destination-city,
	&.destination-country {
		.breadcrumbs {
			.page a {
				left: 0;
				text-decoration: none;
				color: $neutral-secondary-font-color;
			}

			.page {
				float: right;
				margin-right: 2rem;
			}

			.pages:before {
				float: right;
			}

			.page:after {
				float: left;
				left: -1rem;
				top: 0.2rem;
				transform: scaleX (-1);
			}

			.pages {
				float: left;
				margin-left: -5rem;
			}
		}

		.box {
			margin-left: 1rem;
			margin-right: 1rem;
			margin-bottom: 2rem;
			overflow: hidden;
		}

		.search-box {
			.search-box-view.openjaw-view {
				.leg {
					right: 0;
					left: auto;
				}

				.remove-route .icon {
					height: 4rem;
				}

				.add-leg-section .add-route {
					float: left;
				}
			}
		}
	}

	&.destination-city,
	&.destination-country {
		.breadcrumbs {
			display: none;
		}

		.box {
			.box-content {
				display: none;
			}

			.weather-wrapper {
				.weather {
					float: right;
				}
			}
		}

		.long-list {
			ul {
				width: 17rem;
			}
		}
		@include breakpoint_up(md) {
			.breadcrumbs {
				display: block;
			}
		}
	}

	&.destination-country {
		.country-links {
			a {
				&.destination {
					float: right;
				}

				&.price {
					direction: ltr;
					float: left;
				}
			}
			@include breakpoint_up(lg) {
				.selectors {
					left: 0;
					right: auto;
				}
			}
		}
	}

	&.destination-city {
		.cheapest-from {
			.results {
				td {
					float: right;
					margin-left: 2.5rem;
				}

				a {
					&.price {
						float: left;
					}
				}
			}
		}

		.nearest-cities {
			.icon-city {
				float: right;
			}
		}
		@include breakpoint_up(md) {
			.cheapest-from {
				.results {
					td {
						float: right;
						margin-left: 9rem;
					}
				}
			}

			.facts {
				.facts-fact {
					width: 50%;
				}
			}
		}
		@include breakpoint_up(lg) {
			.cheapest-from {
				.results {
					td {
						float: right;
						margin-left: 6.5rem;
					}
				}
			}

			.nearest-cities {
				.icon-city {
					float: right;
					margin-left: 1rem;
				}
			}
		}
	}
	//Topplistor
	&.hitlists {
		.latest-searches {
			right: auto;
			top: 2rem;
			height: 45rem;
			margin-right: 0;
			margin-bottom: 2rem;

			.section.right {
				text-align: left;
			}
		}

		.most-searched {
			padding: 1rem;
			float: right;
		}

		.box-hitlists {
			float: right;
			margin-right: 0.2002%;
			margin-bottom: 0;
			margin-top: 0;
		}
	}

	.most-searched {
		.section-price {
			text-align: left;
			direction: ltr;
		}
	}
	@include breakpoint_up(xl) {
		&.home .search-box {
			position: relative;
		}
	}
	//Sök flyg
	&.search-loading {
		.search-loading-overlay {
			//display: none;
		}

		.latest-searches,
		.select-category {
			display: none !important;
		}
		@include breakpoint_up(md) {
			.search-loading-overlay {
				//display: none !important;
			}

			.latest-searches,
			.select-category {
				display: block !important;
			}
		}
	}

	.top-nav {
		.nav-cell {
			.nav-btn {
				margin: 0 0.5rem !important;
			}

			.nav-cell-text {
				text-align: right;
				margin-right: 1rem;
			}
		}

		.nav-cell:first-of-type {
			.nav-btn {
				margin-right: 0 !important;
			}
		}

		.nav-cell:last-of-type {
			.nav-btn {
				margin-left: 1rem !important;
			}
		}
	}

	&.show-timeline {
		.showresult-timeline-main {
			display: table;
		}

		.menu-sorting {
			.menu-desktop {
				.view-filters-tablet {
					display: block;
					margin-top: 0.5rem;
				}

				.result-disclaimer {
					left: 10rem;
				}
			}
		}

		.filters-column.visible {
			display: block;
			left: -0.8rem;
			right: auto;
		}

		.filters-column .filters {
			left: 0;
			right: auto;
		}
	}

	.search-box {
		.search-box-searching {
			.logos {
				padding-right: 2.5rem;
			}
		}

		.search-box-view.showresult-view {
			/*
			.calendar-wrapper-free {
				left: 10rem;
				right: auto;
			}
			*/

			.inbound-container {
				.icon {
					transform: scaleX(1);
				}
			}
		}
	}
	//Priskalender
	.top-nav-dropdowns {
		.dropdown.visible {
			margin-left: 0.5rem;
			margin-right: 0.5rem;
		}
	}

	.price-matrix-table {

		.bar {
			color: $result-price-font-color;

			.percent.percent-displace {
				left: -2rem;
				color: $white;
			}
		}

		.price.cheapest {
			right: 0.2rem;
			left: auto;

			.text {
				right: 0.2rem;
				left: auto;
			}
		}

		.price.fastest {
			left: 0.2rem;
			right: auto;

			.text {
				left: 0.2rem;
				right: auto;
			}
		}
	}

	.price-matrix-table th {
		.bg-shape {
			background: linear-gradient(to right bottom, $main-btn-bg 49%, $very-disabled-bg 51%);
		}

		.outbound {
			left: -1rem;
			top: 1rem;
			transform: rotate(-25deg);
		}

		.inbound {
			right: -1.4rem;
			bottom: 2.3rem;
			top: 1.8rem;
			transform: rotate(-25deg);
		}
	}

	.price-matrix-table .has-price .bg-shape {
		background: linear-gradient(to left bottom, #fff 49%, $very-disabled-bg 51%);
	}

	.price-matrix-controls.vertical {
		right: -1rem;
	}


	.price-matrix-btns {
		.btn {
			margin-right: 2rem;

			.btn-desktop {
				margin-right: 0;
			}
		}
	}

	.price-matrix-btns span {
		margin-right: 2rem;
	}

	.price-matrix-price-per-adult {
		margin-right: 2rem;
	}

	.price-matrix-guide2 {
		.price.cheapest {
			right: -1rem;
			left: auto;
			text-align: right;
		}

		.price.fastest {
			left: 1rem;
			right: auto;
			text-align: left;
		}

		.shapes {
			transform: scaleX(-1);
			left: 0;
			right: auto;
			margin-left: -2rem;
		}

		.prices {
			direction: ltr;
			left: 0;
			right: auto;
			margin-left: -2rem;
		}
	}
	//Prispressaren
	.price-graph-search__col:first-child {
		text-align: left;

		.price {
			float: left;
			margin-right: 0.4rem;
		}
	}
	//Prisbevakaren
	.search-box.price-watcher-compact {
		.price-watcher-compact-price-tag {
			float: left;

			.price {
				float: left;
			}
		}

		.btn-save {
			display: block;
		}

		.max-price-container {
			margin-top: -2rem;
			overflow: hidden;

			.input-container {
				margin-top: 2rem;
			}

			.text {
				padding: 0 1rem 0 0;
				text-align: right;
			}
		}
	}
	//Lista
	.trip {
		.flight {
			.flight-row {
				strong {
					float: none;
				}

				.flight-row-cell.stops.red {
					.dot {
						border-color: $result-price-font-color;
					}
				}

				.flight-row-cell.stops {
					.dot {
						right: 35%;
					}
				}
			}
		}
	}
	//Tidtabell
	.showresult-timetable {
		.table-container {
			.field.field-price {
				direction: ltr;
				text-align: left;
			}

			.num-of-trips {
				left: 3rem;
				right: auto;
			}

			.top-text {
				right: 3rem;
				left: auto;
			}

			.list-sort {
				.sort {
					.text:after {
						left: 0;
						right: auto;
					}
				}
			}
		}

		.table-container .expander {
			left: 2rem;
			right: auto;

			.arrows {
				left: -1.5rem;
				right: auto;
			}
		}
	}

	.icon-aircraft-up-reversed:before {
		content: "\e601";
		transform: scale (-1, 1);
	}

	.icon-aircraft-up:before {
		content: "\e630";
	}
	//Tidslinje
	.menu-sorting.menu-view .menu-list-item.timeline-view {
		display: none;
	}
	//Resultat
	.menu-sorting .menu-section:first-child .menu-list-item .menu-text:last-child {
		direction: ltr;
		text-align: right;
	}

	.menu .menu-icon--eco {
		left: 0;
		right: auto;
	}

	.showresult {
		.result-wrapper {
			.trips {
				.bound span {
					float: right;
					padding-left: inherit;
				}
			}
		}
	}

	.results-wrapper {
		.results-container {
			.outer-left {
				border-left: 0.1rem solid $neutral-border-color;
			}
		}
	}

	.abResult {
		.abBuyBtn {
			&__previewPrice {
				direction: ltr;
			}
		}

		&__emission {
			.emission-box {
				direction: ltr;
				text-align: right;
			}

			.emission-description {
				text-align: left;
			}
		}
	}

	.trip {
		.airline-logo {
			margin-right: 1rem;
		}

		.airlines.has-wait {
			border-left: 0.1rem $black;
			padding-bottom: 4rem;
		}

		.flight {
			border-right: 0.1rem dotted $neutral-border-color;
			border-left: none;

			.flight-row .flight-row-cell.stops .iata-wait-time .icon-clock {
				display: inline-block;
				margin-left: 0.3rem;
			}

			.flight-row-cell {
				.time-iata {
					margin-right: 0.5rem;
				}
			}
		}
	}

	.result {
		.more-agencies-footer {
			.footer-row {
				.footer-cell.more-agencies {
					padding-left: 1rem;
					text-align: left;
				}

				.footer-cell.agent-cell {
					.agent-price {
						direction: ltr;
					}
				}

				.agent-name {
					margin-left: 0.5rem;
				}
			}
		}

		.right .right-inner .right-table-info .btn-more-info .btn-icon {
			border-right: 0.1rem solid $color-blue-400;
		}

		.go-to-section .btn-go-to .btn-text.price i {
			float: left;
			transform: scaleX(-1);
			margin-left: 0.5rem;
		}
	}

	.result.expanded {
		.flight {
			.flight-row-cell:nth-of-type(2) {
				transform: rotateY(180deg);
			}

			.flight-row-cell.leg-traveltime {
				float: left;
				margin-left: 1rem;
			}
		}
	}

	.search-box {
		.to-container {
			.input-container {
				.icon {
					transform: scale(-1, 1);
				}
			}
		}

		.outbound-container {
			.icon {
				transform: scale(-1, 1);
			}
		}

		.search-box-calendars-container {
			.search-box-calendars-container-cell {
				padding: 0 0 0 1.5rem;
			}

			.search-box-calendars-container-cell:nth-child(2) {
				padding: 0 0.5rem 0 0;
			}
		}
	}

	.filters {
		.filter-includedprice-section .filter-beta-symbol {
			left: 1rem;
			right: auto;
		}

		.filters-choice-radios .label {
			margin-right: 0.5rem;
		}

		.filter-slider-box {
			.filter-value {
				direction: ltr;
			}

			.name {
				text-align: right;
			}
		}

		.btn-expander .text {
			right: 0;
			left: auto;
		}

		.filter-airlines {
			.btn-expander {
				height: 3.2rem;
			}
		}

		.filter-airport-stops {
			.btn-expander {
				height: 2.4rem;
			}
		}
	}

	&.prisbevakaren {
		.search-box {
			width: 100%;

			.section-status {
				.text {
					margin-right: 0;
				}

				.price-tag {
					left: -1rem;
					right: auto;
				}

				.price {
					float: left;
					margin-right: 1rem;
				}

				.price-tag:before {
					transform: scaleX(-1);
					right: 0;
					left: auto;
				}
			}
		}

		.btns {
			display: block;

			.btn {
				float: left;
			}

			.btn-clear {
				margin-left: 1rem;
				margin-top: 0;
			}

			.btn-text {
				display: block;
			}
		}

		.box {
			float: right;
			margin-left: 1rem;
		}

		.left {
			margin-right: 1rem;
		}
	}

	&.search-box {
		.search-box-view.openjaw-view {
			.search-box-other {
				.section {
					display: block;
				}
			}

			.add-leg-section {
				float: left;
			}

			.selectbox-children {
				margin-right: 0;
			}
		}
	}

	.search-box .search-box-child-selects .selectbox {
		float: right;

		.select {
			direction: rtl;
			padding-right: 0.4rem;
			padding-left: 4rem;
		}
	}

	.most-searched__item:nth-child(odd) {
		padding: 0 0.5rem;
	}

	.most-searched__txt-wrapper {
		right: 0;
	}

	.most-searched__item {
		padding: 0 0.5rem;
		margin-left: 0;
	}

	.search-box {
		.search-box-other {
			.btn-icon {
				border-right: 0.1rem solid rgba($black, .1);
			}
		}
	}

	.news-letter .input-block-text {
		font-size: 1.7rem;
	}

	.menu-sorting-footer .menu-section ul li span.menu-icon {
		margin-left: 1rem;
	}

	.filters-wrapper .footer-filter-toggle span.text {
		top: 1rem;

		.icon {
			margin-left: 1rem;
		}
	}

	.search-box .search-box-calendars-container .search-box-calendars-container-cell:nth-child(2) .calendar-container .calendar-wrapper-free {
		right: auto;
		left: 0;
	}

	.menu {
		.menu-text {
			&.sort-price {
				direction: ltr;
			}
		}
	}

	.menu .menu-text {
		text-align: right;
		direction: ltr;
	}
	@include breakpoint_up(md) {
		.menu-desktop .menu-section:first-child ul.menu-list {
			width: 100%;
		}

		&.prisbevakaren &.lastminute {
			.menu {
				.menu-list {
					float: left;
					display: table;
				}
			}

			.menu-secondary {
				.menu-mobile {
					display: block;
					float: right;
					margin-right: 1rem;
				}
			}
		}

		header .nav-mobile .language-select-container {
			.language-select {
				display: none;
			}
		}

		.filters-column {
			.close {
				left: 0;
				right: auto;
			}
		}
	}
	@include breakpoint_up(md) {
		.menu-desktop {
			.view-filters-tablet {
				display: table-cell;
				float: none;
				border-right: 0.1rem solid $main-border-color;
				padding: 0 3rem;

				.menu-text {
					text-align: center;
				}
			}
		}

		.menu-sorting .menu-desktop .menu-section:first-child {
			float: right;
		}

		&.lastminute {
			.menu {
				.menu-list {
					float: left;
					display: table;
				}
			}

			.menu-secondary {
				.menu-mobile {
					display: block;
					float: right;
					margin-right: 1rem;
				}
			}
		}

		.menu-sorting .menu-desktop .result-disclaimer {
			display: none;
		}

		.filters-column {
			left: 0;
			right: auto;
		}

		.top-nav {
			.nav-cell:last-of-type {
				.nav-btn {
					margin-left: 0 !important;
				}
			}
		}

		&.prisjagaren .top-information section.container h1 {
			font-size: 2.0rem;
		}

		&.information .top-information section.container h1 {
			font-size: 2.0rem;
		}
	}
	@include breakpoint_up(lg) {
		.search-box-calendars-container {
			.search-box-calendars-container-cell {
				padding: 0;
			}
		}

		.top-nav {
			.nav-cell:last-of-type {
				.nav-btn {
					margin-left: 0.5rem !important;
				}
			}
		}

		.top-nav-dropdowns {
			.dropdown.visible {
				display:block;
			}
		}

		.search-box .search-box-view.showresult-view .cell-container {
			.directflights-checkbox {
				text-align: right;
			}

			.nearby-checkbox {
				text-align: right;
			}
		}

		&.home .top-information .section:nth-child(2) {
			width: 34.86851%;
			float: left;
		}

		.top-information .desc {
			font-size: 1.7rem;
		}

		&.prisjagaren {
			.menu-secondary {
				.menu-mobile {
					display: table;
					float: right;
					padding: 1rem;
				}

				.menu-section.months {
					display: none;
				}
			}
			.filters-top .title{
				font-size: 1.9rem;
			}
		}

		.menu-desktop {
			display: flex;
			justify-content: space-between;
			/*.menu-section:first-child {
				display: flex;
				position: absolute;
				order: 1;
			}*/
			.view-filters-tablet {
				display: table-cell;
				border-right: 0.1rem solid $main-border-color;

				.filters-column {
					.visible {
						display: block;
						left: -0.8rem;
						right: auto;
					}
				}
			}
		}

		.menu-sorting .menu-desktop .result-disclaimer {
			display: table-cell;
			text-align: center;
			top: 1.4rem;
			left: -6rem;
			font-size: 1.4rem;
		}

		.menu-sorting .menu-desktop .menu-section:first-child {
			float: right;
		}

		.header {
			.section-nav {
				display: table;
				height: 100%;
				margin-right: 6rem;
			}
		}

		.search-box .search-box-view.openjaw-view .search-box-extra-options-container .selectbox {
			padding-left: 1.8rem;
		}

		.most-searched__item:nth-child(odd) {
			padding: 0 0.5rem;
		}

		.most-searched__txt-wrapper {
			right: 0;
		}

		.most-searched__item {
			padding: 0 0.5rem;
			margin-left: 0;
		}

		.search-box {
			.search-box-view.openjaw-view .selectbox-children {
				margin-right: 1rem;
			}
		}

		.search-box .search-box-child-selects {
			.selectbox {
				float: right;

				.select {
					direction: rtl;
					padding-right: 0.4rem;
					padding-left: 4rem;
				}
			}
		}

		.search-box.is-showresult.show-openjaw .search-box-other .selectbox.childrens-wrapper {
			//margin-right: -1rem;
		}

		&.search-box {
			.search-box-view.openjaw-view {
				.search-box-other {
					.section {
						display: block;
					}
				}

				.selectbox-children {
					margin-right: 0;
				}

				.search-box-other.openjaw {
					margin-top: -1rem;
				}

				.cell-calendar {
					.input-container {
						.icon {
							transform: scaleX(-1);
							margin-left: -0.5rem;
							margin-top: -0.2rem;
						}
					}
				}
			}

			.search-box-choice-radios {
				.radio {
					border-left: 0.2rem solid $main-border-color;
				}
			}
		}

		// .search-box .search-box-view.openjaw-view .add-leg-section .add-route {
		// 	margin-left: 0;
		// }

		.input-container {
			.input {
				padding: 0;
				font-size: 1.6rem;
			}
		}

		.search-button-container {
			.btn-search {
				display: table;
				float: right;
			}
		}

		.search-box-view.default-view {
			.from-container,
			.to-container {
				float: right;
				margin-right: 0.2002%;

				.label {
					display: block;
					font-size: 1.7rem;
				}
			}

			.search-box-switch-destinations {
				float: right;
			}
		}

		&.prisbevakaren {
			.section.container {
				padding: 0 1rem;
				margin-right: -1rem;
			}

			.search-box {
				display: block;
				width: 100%;

				.section-status {
					.price-tag:before {
						right: -0.1rem;
						left: auto;
					}
				}

				.btns {
					display: block;

					.btn {
						float: left;
					}

					.btn-clear {
						margin-left: 1rem;
						margin-top: 0;
					}

					.btn-text {
						display: block;
					}
				}

				.cell {
					float: right;
					margin-right: 0;
				}

				.cell:last-child {
					float: left;
				}
			}

			.left {
				margin-right: 1rem;
			}

			.box {
				float: right;
				margin-left: 1rem;
			}
		}

		&.hitlists {
			.latest-searches {
				margin-right: -1rem;
				right: 2rem;
				top: 0;
				height: 44.8rem;
				width: 100%;

				.section.right {
					text-align: left;
				}
			}

			.container {
				right: 1rem;
				left: auto;
			}
		}

		.sectionIntro {
			clear: left;
		}

		&.destination-city {
			.search-box {
				.search-box-view.default-view {
					.search-box-other {
						float: right;
					}
				}

				.search-box-view.openjaw-view {
					.leg {
						right: 0;
						left: auto;
					}

					.remove-route {
						height: 4rem;
					}

					.add-leg-section .add-route {
						float: left;
					}
				}
			}
		}

		.search-box-view.default-view {
			.search-box-other {
				float: left;
				padding-left: 0;
			}
		}

		.search-box.price-watcher-compact {
			.btn-save {
				display: block;
			}

			.cell:nth-child(2) {
				float: right;
				margin-right: 1rem;
			}

			.cell:nth-child(3) {
				margin-left: 1rem;
			}

			.max-price-container {
				overflow: hidden;

				.input-container {
					margin-top: 2rem;
				}

				.text {
					padding: 0 1rem 0 0;
					text-align: right;
				}
			}
		}

		&.lastminute {
			.hero-information section h2 {
				font-size: 2.8rem;
			}

			.hero-information section .desc {
				font-size: 1.8rem;
			}

			.menu {
				.menu-list {
					float: left;
					display: table;
				}
			}

			.menu-secondary {
				.sorting-columns {
					display: none;
				}

				.menu-mobile {
					display: block;
					float: right;
					margin-right: 1rem;
				}
			}

			.menu-desktop {
				display: flex;
			}

			.lastminute-list-container .lastminute-list-row {
				.lastminute-list-cell {
					.outclick-box {
						float: left;

						.price-original-mobile {
							direction: ltr;
						}
					}
				}

				.lastminute-list-cell.tripcontainer-cell tr:first-child td:nth-child(2):before {
					right: -2rem;
					left: auto;
					transform: scaleX (-1);
				}
			}
		}

		.filters-column {
			.close {
				transform: scaleX(-1);
				left: 0;
				right: auto;
			}
		}

		footer .container--local .local-title {
			margin-right: 0 !important;
		}
	}
	@include breakpoint_up(xl) {
		.search-box .search-box-view.showresult-view .cell.cell-search-full {
			margin-left: 0.6rem;
		}

		.search-box-view.showresult-view {
			.calendar-wrapper-free {
				left: 10rem;
				right: auto;
			}
		}

		.search-box-passenger-selects {
			margin-top: 5rem;
			width: 100%;
		}

		.filters-column {
			display: block;
			position: relative;
			top: auto;
			bottom: auto !important;
			height: auto;
			border: 0 !important;
			margin-left: 0.8rem;

			.close {
				display: none;
			}

			.filters {
				overflow-y: auto !important;
				height: auto !important;
				left: 1.5rem;

				.filters-content {
					padding: 0 !important;

					.checkbox label span {}

					.checkbox label:before {
						cursor: pointer;
					}

					.options {
						overflow: hidden;
					}
				}

				.filter-airports-section {
					.checkbox {
						.price {
							float: none;
						}
					}
				}

				.filter-slider-box {
					.name {
						text-align: right;
					}
				}

				.btn-expander {
					.text {
						right: 0;
					}
				}
			}
		}

		.menu-desktop {
			display: table;
			justify-content: space-between;

			.menu-section:first-child {
				display: table;
				position: relative;
				order: 1;
				float: right;
			}

			.view-filters-tablet {
				display: none;
			}
		}

		.menu .menu-list-item:after {
			left: 0;
			right: auto;
		}

		.menu-sorting .menu-desktop .result-disclaimer {
			display: table-cell;
			position: absolute;
			text-align: left;
			float: left;
			top: 0.5rem;
			left: 1rem;
			right: auto;
		}

		.menu-sorting .menu-desktop .menu-section:first-child {
			float: right;
		}

		.view-filters-tablet {
			.filters-column {
				.filters {
					height: auto;
					overflow-y: hidden;
				}
			}
		}

		.header {
			.section-nav {
				display: table;
				height: 100%;
				margin-right: 6rem;
			}
		}

		.input-container {
			.input {
				padding: 0;
			}
		}

		.filters-column {
			display: block;
			margin-right: 1.5rem;
		}

		.results-wrapper {
			.results-column {
				margin-left: 1.5rem;
			}

			.ad-column {
				margin-right: 0.5rem;
				margin-left: 1.5rem;
			}
		}

		.search-box-view.openjaw-view {
			.search-box-other {
				.section {
					display: block;
				}
			}
		}

		.search-box-other {
			margin-right: 0;
			clear: none;

			.btn-icon {
				border-right: 0.1rem solid rgba($black, .1);
			}
		}

		&.prisjagaren {
			.menu {
				.view-type {
					display: block;
				}

				.menu-list {
					display: table;
					float: left;
				}
			}

			.menu-secondary {
				&.menu .menu-section.months {
					display: flex;
					float: right;
					position: absolute;
					z-index: 1;
				}
			}

			.selectbox {
				display: none;
			}
		}

		&.prisbevakaren {
			.search-box {
				.section-dates .info {
					font-size: 1.5rem;
				}

				.btns {
					display: block;

					.btn {
						float: left;
					}

					.btn-clear {
						margin-left: 1rem;
						margin-top: 0;
					}

					.btn-text {
						display: block;
					}
				}

				.cell {
					float: right;
					margin-right: 0;
				}

				.cell:last-child {
					float: left;
				}
			}

			.left {
				margin-right: 1rem;
				float: right;
			}

			.right {
				margin-right: 1rem;
			}
		}

		&.destination-city {
			.search-box {
				.search-box-view.default-view {
					.search-box-other {
						float: left;
						margin-left: 0;
					}
				}

				.search-box-view.openjaw-view {
					.leg {
						right: 0;
						left: auto;
					}

					.remove-route .icon {
						height: 4rem;
					}

					.add-leg-section .add-route {
						float: left;
					}

					.search-button-container .btn-search {
						float: left;
					}

					.icon.icon {
						transform: scalex(-1);
					}

					.icon-aircraft-up:before {
						content: "\e601";
					}
				}
			}
		}

		.search-box.price-watcher-compact {
			.btn-save {
				display: block;
			}

			.cell:nth-child(2) {
				float: right;
				margin-right: 1rem;
			}

			.cell:nth-child(3) {
				margin-left: 1rem;
			}

			.max-price-container {
				overflow: hidden;

				.input-container {
					margin-top: 2rem;
				}

				.text {
					padding: 0 1rem 0 0;
					text-align: right;
				}
			}
		}

		&.lastminute {
			.container h2 {
				font-size: 2.0rem;
			}

			.show-more .text {
				font-size: 1.5rem;
			}

			.menu-secondary {
				.menu-mobile {
					display: none;
				}
			}

			.lastminute-list-container .lastminute-list-row .lastminute-list-cell .outclick-box {
				.discount-badge {
					right: -4.5rem;
					direction: ltr;

					.badge-edge {
						left: -2.4rem;
						right: auto;
					}
				}
			}
		}
	}

	.switch-toggle {
		&:checked {
			& + label {
				&:after {
					margin-left: 0;
					margin-right: 1.2rem;
				}
			}
		}
	}

	.autocomplete-container {
		@media screen and (max-width: 768px) {
			&:before, &:after {
				left: auto;
			}
		}
	}

	.abLanguageSelector__locals {
		right: auto;
		left: 1rem;

		&:before, &:after {
			right: auto;
			left: 1.2rem;
		}
	}

	.abLanguageSelector__local {
		& > span {
			margin-left: auto;
			margin-right: 1.2rem;
		}
	}

	.abTooltip {
		&--baggage {
			@include breakpoint_up(xxs) {
				left: 0;
				right: auto;
			}
		}
	}

	.abTabNav {
		&__btn {
			&:first-child {
				border-right-color: transparent;
			}
			&:last-child {
				border-right-color: inherit;
			}
		}
	}

	.abSearchBox {
		&__btn {
			&--clear {
				right: auto;
				left: 1rem;
			}
		}

		&__row {
			&--focus-to {
				.abSearchBox__to {
					text-align: right;
				}
			}
		}
	}

	.abDateSelector {
		&__footer {
			padding-left: 0;
			padding-right: 1.6rem;
		}
	}

	&.information {
		main {
			.container {
				.accordion {
					padding: 1rem;
					margin: 0;
				}
				.accordion-slide-top
				{
					.icon {
						float: none;
						position: initial;
						top: auto;
					}
				}
			}
		}
	}
}
