.footer {
	.footer-top {
		display: none;
	}

	.footer-bottom {
		text-align: center;
	}

	.localisation {
		display: inline-block;
		position: absolute;
		left: 0;

		&.to-mobile-site {
			position: relative;

			.link {
				text-decoration: underline;
			}
		}
	}
}

.home .latest-searches {
	.list-container {
		height: 100%;
	}
}

.result.expanded {
	.icon-view-flightmap {
		&:hover {
			&:after {
				content: none !important;
			}
		}
	}
}
