.localized-da {

	header .logo-text {
		left: 13.4rem;
	}

	.selectbox {
		&--trip-type {
			& > select {
				padding-left: 8.2rem;
			}		
		}
		&--ticket-type-inline {
			& > select {
				padding-left: 8.2rem;
			}
		}
	}

}

