.localized-de {

	.selectbox {
		&--trip-type {
			& > select {
				padding-left: 7.2rem;
			}		
		}
		&--ticket-type-inline {
			& > select {
				padding-left: 12.4rem;
			}
		}
	}

	.news-letter {
		max-width: 51rem;
	}

	&.hitlists {
		.menu-list li {
			padding: 0;
			font-size: 1.2rem;
		}
		@include breakpoint_up(sm) {
			.menu-list li {
				padding: 0 1rem;
				font-size: 1.4rem;
			}
		}
	}

	.global-container.test-new {
		.bound .top-time {
			margin-right: 0.4rem !important;
			letter-spacing: -0.1rem;
			@include breakpoint_up(sm) {
				margin-right: 1rem !important;
				letter-spacing: 0;
			}
		}
	}

	.search-box-choice-radios .radio:first-of-type {
		width: 13rem;
		@include breakpoint_up(sm) {
			width: auto;
		}
	}

	.filters-categories {
		table-layout: fixed;

		.category {
			word-wrap: break-word;
			hyphens: auto;
		}
	}

	.price-matrix-guide2 {
		.price {
			&.cheapest {
				font-size: 0.9rem;
			}

			&.fastest {
				font-size: 0.9rem;
				margin-right: 0.2rem;
				top: 1.7rem;
			}
		}
	}

	.result {
		.go-to-section {
			.price-info {
				right: 0;
			}
		}
	}

	.abResult {
		&__emission {
			.emission-description {
				span:last-child {
					display: inline;
				}
			}
		}
	}

	.search-box-view {
		&.showresult-view {
			.btn-search {
				min-width: inherit;
			}
		}
	}

	.top-information {
		.section {
			&:nth-child(2) {
				display: none;
			}
			@include breakpoint_up(lg) {
				&:nth-child(2) {
					display: block;
				}
			}
		}
	}
	
	@include breakpoint_up(md) {
		&.search-loading {
			.section-top {
				transition: 0.1s margin;

				.top-nav {
					transition: 0.1s bottom;
				}
			}

			.search-box-searching {
				transition: 0.1s margin;
			}
		}

		.price-matrix-popup {
			.btn-renew-result {
				font-size: 1.2rem;
			}
		}

		.trip .airlines {
			max-width: 8rem;
			font-size: 1rem;
		}

		.search-box.price-watcher-compact {
			.max-price-container,
			.section-status {
				line-height: normal;
				white-space: normal;
			}

			.max-price-container {
				.text {
					float: left;
					width: 45%;
				}

				.input-container {
					width: 50%;
					float: right;
					margin: 0;
				}
			}
		}

		.result.expanded .flight .flight-row-cell:first-of-type,
		.result.expanded .flight .flight-row-cell:nth-of-type(3) {
			width: 14rem;
			max-width: 14rem;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.menu .menu-icon--eco {
			z-index: 2;
			right: -1rem;
		}

		.section-top {
			.warning {}

			.top-nav {
				.nav-btn {
					height: auto;

					.pricecalendar-description,
					.pricecutter-description,
					.pricewatcher-description {
						display: none!important;
						@include breakpoint_up(xl) {
							display: inline-block !important;
						}
					}
				}
			}
		}
	}
	@include breakpoint_up(lg) {
		.search-box-view {
			&.showresult-view {
				.cell-pass {
					width: 12rem;
				}
			}
		}
	}
	@media only screen and (max-device-width: 580px) {
		header {
			.logo {
				margin-left: 6rem;
			}
		}
		@include breakpoint_up(xs) {
			header {
				.logo {
					margin-left: 8rem;
				}
			}
		}
	}
}
