.localized-no {

	.selectbox {
		&--trip-type {
			& > select {
				padding-left: 8.2rem;
			}		
		}
		&--ticket-type-inline {
			& > select {
				padding-left: 7.8rem;
			}
		}
	}

}