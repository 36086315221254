.localized-fr {

	.selectbox {
		&--trip-type {
			& > select {
				padding-left: 12.4rem;
			}		
		}
		&--ticket-type-inline {
			& > select {
				padding-left: 11rem;
			}
		}
	}

	&.prisbevakaren {
		@include breakpoint_up(xl) {
			.btn-white {
				margin: 1rem 0 0 0!important;
			}
			.btn-yellow, .btn-white {
				clear: both;
			}
		}
	}
	header .logo-text {
		left: 12.6rem;
	}
	@include breakpoint_up(md) {
		.result.expanded .flights {
			//padding-left: 0;
		}
		.menu .menu-icon--eco {
			z-index: 2;
			right: -1rem;
		}
	}
	@include breakpoint_up(lg) {
		.top-nav .nav-cell {
			.nav-cell-text {
				max-width: 20rem;
				.pricewatcher-description,
				.pricecutter-description,
				.pricecalendar-description
				{
					font-size: 1.1rem;
					min-height: 3rem;
				}

			}
			.nav-btn {
				padding: 0.5rem;
			}
		}
	}
	@include breakpoint_up(xl) {
		.search-box.is-showresult.show-openjaw .add-leg-section .search-box-extra-options-container {
			width: 25rem;
		}
		.top-nav .nav-cell {
			.nav-cell-text {
				max-width: none;
				.pricewatcher-description,
				.pricecutter-description,
				.pricecalendar-description
				{
					font-size: 1.1rem;
					min-height: 0;
				}
			}
			.nav-btn {
				padding: 1.4rem 1rem;
			}
		}
		.search-box .search-box-view.showresult-view .cell-container.search-box-extra-options {
			width: 80%;
		}
		.showresult-trip-type-wrapper, .showresult-ticket-type-wrapper {
			min-width: 25rem;
		}
	}
	/* hide 'open jaw' in parenthesis in search box module */
	.search-box-choice-radios .radio .desktop-text {
		display: none;
	}
	.showresult-trip-type-wrapper {
		min-width: 28rem;
	}

	.abResult {
		&__emission {
			.emission-description {
				span:last-child {
					display: inline;
				}
			}
		}
	}

	.additional-service__description {
		span:last-child {
			font-size: 1.1rem;

			@include breakpoint_up(md) {
				font-size: 1.4rem;
			}
		}
	}
}